import {createTheme} from "@mui/material";

const theme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: '#EA4243',
        },
        secondary: {
            main: '#204056',
        },
        accent: {
            main: "#66BB6A"
        },
        background: {
            default: '#F6F6F6',
        },
    },
    typography: {
        fontFamily: 'Montserrat',
        h1: {
            fontWeight: 800,
            fontSize: '2rem'
        },
        h2: {
            fontWeight: 800,
            fontSize: '1.5rem'
        },
        h3: {
            fontWeight: 800,
            fontSize: '1.25rem'
        }
    },
    components: {
        MuiDrawer: {
            styleOverrides: {
                paperAnchorDockedLeft: {
                    overflow: 'hidden',
                    borderColor: '#fff',
                    borderTopRightRadius: 20,
                    borderBottomRightRadius: 20,
                    '& .MuiListItem-root': {
                        background: '#FFF',
                        color: '#203036',
                        borderRadius: 10,
                        marginBottom: '2rem',
                        padding: '0 0.5rem',
                        '&:hover': {
                            background: '#F6F6F6',
                            textDecoration: 'none'
                        },
                        '& .MuiTypography-root': {
                            fontWeight: 500,
                        }
                    }
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                positionFixed: {
                    boxShadow: "none"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '18px',
                    boxShadow: "none",
                    width: '100%',
                    padding: '12px 24px',
                },
                white: {
                    '&:hover': {
                        backgroundColor: '#fff'
                    }
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                card: {
                    overflow: 'hidden',
                    borderRadius: '20px',
                    boxShadow: '0px 2px 10px rgba(32, 64, 86, 0.12)',
                    backgroundColor: '#FFF'
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    '.MuiPaper-root': {
                        borderRadius: '20px',
                        padding: '0.5rem 2rem'
                    }
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    '&:hover': {
                        backgroundColor: '#fff'
                    }
                }
            }
        }
    }
});

export default theme;
