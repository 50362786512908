import React, {useCallback, useEffect, useState} from "react";
import {usePubNub} from "pubnub-react";
import {
    ChannelList,
    Chat,
    MessageInput,
    MessageList,
    TypingIndicator,
    useChannelMembers,
    useUser,
    useUserMemberships,
} from "@pubnub/react-chat-components";
import "./moderated-chat.scss";
import {Box, InputAdornment, Paper, Stack, TextField, Typography} from "@mui/material";
import {SearchRounded} from "@mui/icons-material";
import logo_circle from "../assets/circle_logo.png"

const defaultChannel = {
    id: "atoto-cart-1",
    name: "Cart 1",
    description: "This is the default channel",
};

export default function ModeratedChat() {
    /**
     * Component state related hooks
     * Those mostly store the current channel, modals and side panels being shown
     */
    const [currentChannel, setCurrentChannel] = useState(defaultChannel);
    const [showChannels, setShowChannels] = useState(true);
    const [channelsFilter, setChannelsFilter] = useState("");

    /**
     * All data related to Users, Channels and Memberships is stored within PubNub Objects API
     * It can be easily accessed using React Chat Components hooks
     */
    const pubnub = usePubNub();
    const uuid = pubnub.getUUID();
    const [currentUser] = useUser({uuid});
    const [joinedChannels, , refetchJoinedChannels] = useUserMemberships({
        include: {channelFields: true, customChannelFields: true},
    });
    const [channelMembers, , refetchChannelMemberships, totalChannelMembers] = useChannelMembers({
        channel: currentChannel.id,
        include: {customUUIDFields: true},
    })
    const directChannels = [{id: "atoto-cart-1", name: "Cart 1"}]
        .filter((c) => c.name?.toLowerCase().includes(channelsFilter.toLowerCase()));

    const isUserMuted = (currentUser?.custom?.mutedChannels?.split(",")?.includes(currentChannel.id));

    const refreshMemberships = useCallback(
        (event) => {
            if (event.channel.startsWith("user_")) refetchJoinedChannels();
            if (event.channel === currentChannel.id) refetchChannelMemberships();
        },
        [currentChannel, refetchJoinedChannels, refetchChannelMemberships]
    );

    /**
     * Handling publish errors
     */
    const handleError = (e) => {
        if (
            (e.status?.operation === "PNPublishOperation" && e.status?.statusCode === 403) ||
            e.message.startsWith("Publish failed")
        ) {
            alert(
                "Your message was blocked. Perhaps you tried to use offensive language or send an image that contains nudity?"
            );
        }

        console.warn(e);
    };

    useEffect(() => {
        if (currentChannel.id === "default" && joinedChannels.length)
            setCurrentChannel(joinedChannels[0]);
    }, [currentChannel, joinedChannels]);

    /**
     * Rendered markup is a mixture of PubNub Chat Components (Chat, ChannelList, MessageList,
     * MessageInput, MemberList) and some elements to display additional information and to handle
     * custom behaviors (channels modal, showing/hiding panels, responsive design)
     */
    return (
        <Box className={`app-moderated app-moderated--light`} style={{background: "transparent", height: '100%'}}>
            {/* Be sure to wrap Chat component in PubNubProvider from pubnub-react package.
        In this case it's done in the index.tsx file */}
            {/* Current uuid is passed to channels prop to subscribe and listen to User metadata changes */}
            <Chat
                enablePresence={false}
                theme={"light"}
                currentChannel={currentChannel.id}
                channels={[...joinedChannels.map((c) => c.id)]}
                onError={handleError}
                onMembership={(e) => refreshMemberships(e)}
                onMessage={(message) => {
                    console.log(message);
                }}
            >
                <Paper variant={"card"} className={`channels-panel ${showChannels && "shown"}`}
                       sx={{padding: '28px 24px', marginRight: "1.5rem"}}>
                    <Typography variant={"h3"} color={"black"} sx={{mb: 2}}>Message</Typography>

                    <div className="filter-input">
                        <TextField
                            onChange={(e) => setChannelsFilter(e.target.value)}
                            placeholder="Search cart or messages..."
                            type="text"
                            size={"small"}
                            value={channelsFilter}
                            sx={{width: '100%'}}
                            variant={"outlined"}
                            InputProps={{
                                style: {borderRadius: '18px'},
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchRounded/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>

                    <Box className="channel-lists" sx={{mt: 1}}>
                        <ChannelList
                            channels={directChannels}
                            onChannelSwitched={(channel) => {
                                setCurrentChannel(channel);
                                // actionCompleted({ action: "Switch to a new 1:1 or Group Chat" });
                            }}
                            channelRenderer={(channel) =>
                                <Box
                                    className={"chat-cart-list-item " + (currentChannel.id == channel.id ? 'active' : '')}
                                    onClick={() => setCurrentChannel(channel)}
                                    sx={{
                                        padding: '16px 12px',
                                        borderRadius: '1rem',
                                        cursor: 'pointer',
                                        marginBottom: '8px'
                                    }}>
                                    <Stack direction={"row"} alignItems={"center"}>
                                        <img src={logo_circle} style={{
                                            width: '2.5rem',
                                        }}/>
                                        <Typography sx={{ml: '8px'}} variant={"body1"} fontWeight={700}
                                                    fontSize={'14px'}
                                                    color={(currentChannel.id == channel.id ? 'white' : 'black')}>
                                            {channel.name}
                                        </Typography>
                                    </Stack>
                                </Box>}
                        />
                    </Box>
                </Paper>

                <Paper variant={"card"} sx={{
                    display: 'flex', flexDirection: 'column', position: 'relative',
                    padding: '28px 24px', flexGrow: 1
                }}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}
                           sx={{mb: 3}}>
                        <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"}>
                            <div style={{position: "relative"}}>
                                <img src={logo_circle} style={{
                                    width: '3.75rem',
                                    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
                                }}/>
                                {currentChannel.name == 'Cart 1' &&
                                    <div style={{position: "absolute", right: 0, bottom: 0}}>
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="9" cy="9" r="8" fill="#5CC689" stroke="white"
                                                    stroke-width="2"/>
                                        </svg>
                                    </div>}
                            </div>
                            <Stack sx={{ml: 3}} direction={"column"} justifyContent={"flex-start"}
                                   alignItems={"flex-start"}>
                                <Typography
                                    variant={"h2"}>{currentChannel.name || currentChannel.id}</Typography>
                                {currentChannel.name == 'Cart 1' &&
                                    <Typography variant={"caption"}>Online</Typography>}
                            </Stack>
                        </Stack>
                    </Stack>
                    <hr style={{borderColor: "rgba(32, 32, 32, 1);"}}/>
                    <MessageList
                        fetchMessages={15}
                        enableReactions={false}
                        messageRenderer={(props) => <Stack
                            direction={(props.message.publisher == 'atoto-clubhouse' || props.message.uuid == 'atoto-clubhouse') ? "row" : "row-reverse"}
                            sx={{width: "100%", mb: 2}}
                            justifyContent={'flex-end'}
                            alignItems={"center"}>
                            <Typography variant={"body1"} sx={{mr: ((props.message.publisher == 'atoto-clubhouse' || props.message.uuid == 'atoto-clubhouse') ? 2 : 0), ml: ((props.message.publisher == 'atoto-clubhouse' || props.message.uuid == 'atoto-clubhouse') ? 0 : 2)}}>
                                {(new Date(props.message.message.createdAt)).toLocaleTimeString('it').substring(0, 5)}
                            </Typography>
                            <Typography variant={"body1"} style={{
                                background: (props.message.publisher == 'atoto-clubhouse' || props.message.uuid == 'atoto-clubhouse') ? "rgba(232, 235, 238, 1)" : "rgba(74, 100, 119, 1)",
                                padding: '0.5rem 1rem',
                                borderRadius: '12px',
                                fontSize: '1.5rem',
                                color: (props.message.publisher == 'atoto-clubhouse' || props.message.uuid == 'atoto-clubhouse') ? "rgba(32, 48, 54, 1)" : "white"
                            }}>
                                {props.message.message.text}
                            </Typography>
                        </Stack>}
                    />
                    <TypingIndicator/>

                    <MessageInput
                        disabled={isUserMuted}
                        typingIndicator
                        placeholder={isUserMuted ? "You were muted from this channel" : "Write message..."}
                        onBeforeSend={(value) => {
                            console.log(value)
                        }}
                        onSend={(message) => {
                            /*actionCompleted({
                                action: containsEmoji({ testString: message.text })
                                    ? "Send a Message with an Emoji"
                                    : "Send a Chat Message",
                            });*/
                        }}
                    />
                </Paper>
            </Chat>
        </Box>
    );
}
