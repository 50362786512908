const fillLayerProps = {
    id: 'filllayer',
    type: 'fill',
    source: 'course',
    paint: {
        'fill-color': [
            'match',
            ['string', ['get', 'type']],
            'green', 'rgba(187.0, 233.0, 153.0, 1.0)',
            'perimeter', 'rgba(183.0, 222.0, 144.0, 1.0)',
            'teebox', 'rgba(187.0, 233.0, 153.0, 1.0)',
            'fairway', 'rgba(168.0, 214.0, 126.0, 1.0)',
            ['bunker', 'sand'], 'rgba(245.0, 240.0, 212.0, 1.0)',
            'slopearrow', 'rgba(0, 0, 0, 0.5)',
            ['water'], 'rgba(140.0, 221.0, 247.0, 1.0)',
            'bridge', 'rgba(153,76,0,0.5)',
            'rgba(255, 255, 255, 0.0)'
        ],
        'fill-outline-color': [
            'match',
            ['string', ['get', 'type']],
            'centralpath', 'rgba(0, 0, 0, 0.0)',
            'rgba(255, 255, 255, 0.0)'
        ]
    }
};


const lineLayerProps = {
    id: 'linelayer',
    type: 'line',
    source: 'course',
    paint: {
        'line-color': [
            'match',
            ['string', ['get', 'type']],
            'cross', 'rgba(255, 0, 0, 1)',
            'slopeline', 'rgba(0, 0, 0, 0.3)',
            'centralpath', 'rgba(89, 143, 253, 0.5)',
            'rgba(255, 255, 255, 0.0)'
        ],
        'line-width': 1.5,
    }
};

const creekPathsProps = {
    id: 'creek_paths',
    type: 'line',
    source: 'course',
    paint: {
        'line-color': [
            'match',
            ['string', ['get', 'type']],
            'creek', 'rgba(140.0, 221.0, 247.0, 1)',
            'path', 'rgba(255, 255, 255, 1)',
            'rgba(255, 255, 255, 0.0)'
        ],
        'line-width': 5,
    }
};

const treeLayerProps = {
    id: 'treelayer',
    type: 'circle',
    source: 'course',
    paint: {
        'circle-radius': 2,
        'circle-stroke-width': 1,
        'circle-color': 'green',
        'circle-stroke-color': 'white'
    }
};

const pinLayerProps = {
    id: 'pinlayer',
    type: 'symbol',
    source: 'course',
    layout: {
        'icon-image': 'pin',
        'icon-size': ['interpolate', ['linear'], ['zoom'], 15, 0.05, 20, 0.2],
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
        'icon-rotate': ['get', 'rotation'],
        'icon-anchor': 'bottom-left',
    },
    paint: {
        'icon-opacity': 1
    }
};

const numberProps = {
    id: 'numberlayer',
    type: 'symbol',
    source: 'course',
    layout: {
        'text-field': ['get', 'number'],
        'text-size': 14,
        'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
        'text-offset': [0, 0.6],
        'text-anchor': 'center'
    },
}

export {
    lineLayerProps,
    fillLayerProps,
    creekPathsProps,
    pinLayerProps,
    treeLayerProps,
    numberProps
}
