import * as React from 'react';
import {styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Link, Navigate, Outlet} from "react-router-dom";
import {ReactComponent as PinVisionLogo1} from './../assets/logo.svg';
import {ReactComponent as PinVisionLogo2} from './../assets/logotipo.svg';
import {ReactComponent as BellLogo} from '../assets/bell_dark.svg';
import {ReactComponent as ChartbarLogo} from './../assets/chart-bar_dark.svg';
import {ReactComponent as FlagLogo} from './../assets/flag_dark.svg';
import {ReactComponent as GolfcartLogo} from './../assets/golf_cart_dark.svg';
import {ReactComponent as DashLogo} from './../assets/dash_dark.svg';
import {ArrowBack} from "@mui/icons-material";
import jwt_decode from "jwt-decode";
import {Stack} from "@mui/material";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    paddingLeft: open ? 0 : `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        paddingLeft: open ? 0 : `calc(${theme.spacing(8)} + 1px)`,
    },
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin', 'padding'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin', 'padding'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const links = [
    {
        name: 'Dashboard',
        url: 'https://admin.pinvision.app',
        icon: <DashLogo/>
    },
    {
        name: 'Pin Position',
        url: 'https://admin.pinvision.app/bandiere/percorsi',
        icon: <FlagLogo/>
    },
    {
        name: 'Messaggi',
        url: 'https://admin.pinvision.app/notifiche',
        icon: <BellLogo/>
    },
    {
        name: 'Statistiche',
        url: 'https://admin.pinvision.app/clubhouse',
        icon: <ChartbarLogo/>
    },
    {
        name: 'ATOTO',
        url: '/',
        icon: <GolfcartLogo/>
    }
]

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function PinDrawer({user, title, fullscreen}) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{display: 'flex', height: '100%', backgroundColor: theme.palette.background.default}}>
            <CssBaseline />
            {!fullscreen && <AppBar color={"transparent"} open={open} sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
                <Toolbar>
                    {title === 'Chat' && <IconButton sx={{mr: 2}} component={Link} to={"/"}><ArrowBack /></IconButton>}
                    <Typography variant="h1" noWrap component="h1" sx={{flexGrow: 1}}>
                        {title}
                    </Typography>
                </Toolbar>
            </AppBar>}
            <Drawer variant="permanent" open={open}>
                <DrawerHeader style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <PinVisionLogo1 style={{width: '3rem', flexShrink: 0, margin: '0 .5rem'}} />
                    <PinVisionLogo2 style={{width: '8rem', flexShrink: 0, marginLeft: '2rem'}} />
                </DrawerHeader>
                <Box onMouseEnter={handleDrawerOpen} onMouseLeave={handleDrawerClose}>
                    <List>
                        {links.map((el) => (
                            <ListItem key={el.name}
                                      disablePadding component={"a"} href={el.url}>
                                <ListItemButton>
                                    <ListItemIcon>{el.icon}</ListItemIcon>
                                    <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={el.name} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
            <Stack direction={"column"} component="main" sx={{flexGrow: 1, p: fullscreen ? 0 : 3, backgroundColor: theme.palette.background.default}}>
                {!fullscreen && <DrawerHeader />}
                <Outlet sx={{flexGrow: 1}} />
            </Stack>
        </Box>
    );
}
