import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Box, Stack, Typography} from "@mui/material";
import CartService from "../services/cart.service";

export default function UnlockAlert({cart, open, handleClose}) {

    const changeLockStatus = async () => {
        cart.locked = !cart.locked;
        await CartService.updateCart(cart)
        handleClose()
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Stack direction={"column"} alignItems={"center"}>
                    <Box sx={{mb: 2}}>
                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.4574 11.1242C26.4578 9.12377 29.1709 7.99996 31.9999 7.99996C34.8289 7.99996 37.542 9.12377 39.5424 11.1242C41.5428 13.1245 42.6666 15.8377 42.6666 18.6666V26.6666H21.3333V18.6666C21.3333 15.8377 22.4571 13.1245 24.4574 11.1242ZM15.9999 26.6666V18.6666C15.9999 14.4232 17.6856 10.3535 20.6862 7.35292C23.6868 4.35234 27.7565 2.66663 31.9999 2.66663C36.2434 2.66663 40.313 4.35234 43.3136 7.35292C46.3142 10.3535 47.9999 14.4232 47.9999 18.6666V26.6666H50.6666C55.0849 26.6666 58.6666 30.2484 58.6666 34.6666V53.3333C58.6666 57.7516 55.0849 61.3333 50.6666 61.3333H13.3333C8.91497 61.3333 5.33325 57.7516 5.33325 53.3333V34.6666C5.33325 30.2484 8.91497 26.6666 13.3333 26.6666H15.9999ZM45.3333 32H50.6666C52.1393 32 53.3333 33.1939 53.3333 34.6666V53.3333C53.3333 54.8061 52.1393 56 50.6666 56H13.3333C11.8605 56 10.6666 54.8061 10.6666 53.3333V34.6666C10.6666 33.1939 11.8605 32 13.3333 32H18.6666H45.3333ZM29.3333 42.6666C29.3333 41.1939 30.5272 40 31.9999 40H32.0266C33.4993 40 34.6933 41.1939 34.6933 42.6666C34.6933 44.1394 33.4993 45.3333 32.0266 45.3333H31.9999C30.5272 45.3333 29.3333 44.1394 29.3333 42.6666Z" fill="#203036"/>
                        </svg>
                    </Box>
                    <Typography variant={"h3"}>
                        {"Are you sure you want to " + (!cart?.locked ? "lock" : "unlock") + " this cart?"}
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                {!cart?.locked && <DialogContentText id="alert-dialog-description">
                    <Typography fontWeight={500} textAlign={"center"}>Cart will stop immediately</Typography>
                </DialogContentText>}
            </DialogContent>
            <DialogActions sx={{flexDirection: 'row', justifyContent: 'center'}}>
                <Button sx={{width: 'auto', textTransform: 'none', fontWeight: 700}} onClick={changeLockStatus} variant={"contained"} color={"primary"} autoFocus>
                    Confirm
                </Button>
                <Button sx={{width: 'auto', textTransform: 'none', fontWeight: 700}} onClick={handleClose} variant={"outlined"} color={"primary"}>Dismiss</Button>
            </DialogActions>
        </Dialog>
    );
}
