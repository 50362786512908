import './App.css';
import {Route, Routes} from "react-router-dom";
import SSO from "./pages/SSO";
import {CssBaseline, ThemeProvider} from "@mui/material";
import theme from "./theme";
import ClubMapPage from "./pages/ClubMap";
import React, {useState} from "react";
import ChatPage from "./pages/Chat";
import PinDrawer from "./components/PinDrawer";

function App() {

    const [title, setTitle] = useState('Golf Carts');
    const [fullscreen, setFullscreen] = useState(false);

    return (
        <div id={"main"}>
            <CssBaseline/>
            <ThemeProvider theme={theme}>
                <Routes>
                    <Route path="/sso/:token" element={<SSO/>}></Route>
                    <Route element={<PinDrawer title={title} fullscreen={fullscreen} user={localStorage.user} />}>
                        <Route path="/" exact element={<ClubMapPage setTitle={setTitle} fullscreen={fullscreen} setFullscreen={setFullscreen} />}></Route>
                        <Route path="/chat" element={<ChatPage setTitle={setTitle} />}></Route>
                    </Route>
                </Routes>
            </ThemeProvider>
        </div>
    );
}

export default App;
