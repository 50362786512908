import axios from "axios";
import authHeader from "./user.service";

const getDangerZones = (id_golfclub) => {
    return axios.get(`${process.env.REACT_APP_API}apikey/clubs/${id_golfclub}/danger-zones`, {headers: authHeader()})
}

const setDangerZones = (id_golfclub, dangerZones) => {
    return axios.put(`${process.env.REACT_APP_API}apikey/clubs/${id_golfclub}/danger-zones`, dangerZones, {headers: authHeader()})
}

const getPinPositions = (id_golfclub, id_course) => {
    const today_string = new Date().toISOString().split('T')[0]
    return axios.get(`${process.env.REACT_APP_API}apikey/clubs/${id_golfclub}/courses/${id_course}/targets?date=${today_string}`, {headers: authHeader()})
}

const ClubService = {
    getDangerZones,
    setDangerZones,
    getPinPositions
};

export default ClubService;
