import {Box, Grid, Paper, Stack, SvgIcon, Typography} from "@mui/material";
import MapboxMap from "../components/MapboxMap";
import {ReactComponent as ChatLogo} from '../assets/message-circle.svg';
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {RoomServiceOutlined} from "@mui/icons-material";
import ManageMenu from "../components/ManageMenu";
import axios from "axios";
import CartService from "../services/cart.service";

const ClubMapPage = ({fullscreen, setFullscreen, setTitle}) => {

    const info = [
        {
            name: 'Chat',
            url: '/chat',
            icon: <ChatLogo/>
        },
        {
            name: "Resturant\'s Menu",
            url: '',
            icon: <RoomServiceOutlined/>,
            click: () => handleOpenMenu(process.env.REACT_APP_GOLFCLUB_ID)
        },
    ]
    const [lockMenuOpen, setLockMenuOpen] = useState(false);
    const [carts, setCarts] = useState([])
    const [curCart, setCurCart] = useState(null)

    const handleLockMenuClose = () => {
        setLockMenuOpen(false);
    }

    const handleOpenMenu = async (id) => {
        setLockMenuOpen(true);
    }

    useEffect(() => {
        setTitle("Golf Clubs")
        fetchData().then()
        window.clearInterval()
        let intervalId = window.setInterval(fetchData, 5000);
        return () => {
            window.clearInterval(intervalId)
        }
    }, [])

    const fetchData = async () => {
        const res = await CartService.getCarts()
        setCarts(res.data.golfcarts.Items)
        setCurCart(res.data.golfcarts.Items.filter((cart) => cart.cartId == 6)[0])
    }

    return (
        <Stack spacing={3} sx={{flexGrow: 1, height: '100%'}} justifyContent={"stretch"}>
            <Paper variant={fullscreen ? "outlined" : "card"} sx={{height: '100%'}}>
                <MapboxMap fullscreen={fullscreen} handleFullscreen={setFullscreen} carts={carts} />
            </Paper>
            {!fullscreen && <Box>
                <Grid container spacing={3}>
                    {info.map((el) => ( <Grid item xs={6}>
                        <Link to={el.url} onClick={el.click} style={{textDecoration: 'none'}}>
                            <Paper variant={"card"}>
                                <div style={{padding: '32px 70px 32px 70px', alignItems: 'center', minHeight: '8rem',
                                    display: 'flex', justifyContent: 'space-between'}}>
                                    <Typography variant="h1">
                                        {el.name}
                                    </Typography>
                                    <SvgIcon viewBox="0 0 64 64" sx={{fontSize: 64}}>{el.icon}</SvgIcon>
                                </div>
                            </Paper>
                        </Link>
                    </Grid>))}
                </Grid>
            </Box>}
            <ManageMenu open={lockMenuOpen} handleClose={handleLockMenuClose} cart={curCart} />
        </Stack>
    )
}

export default ClubMapPage;
