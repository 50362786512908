import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Box, Stack, TextField, Typography} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {FileUploader} from "react-drag-drop-files";
import {useState} from "react";
import axios from "axios";
import {useEffect} from "react";
import { Link } from 'react-router-dom';
import CartService from '../services/cart.service';

export default function ManageMenu({open, handleClose, cart}) {

    const [file, setFile] = useState(null);
    const [menuUrl, setMenuUrl] = useState('')

    const handleChangeFile = (file) => {
        setFile(file);
    };

    const sendFiles = async (e) => {
        const res = await CartService.uploadMenu(file.name, file.type)
        console.log(res.data.url)
        // UPload with Put to S3
        await fetch(res.data.url, {
            method: 'PUT',
            body: file,
        });
        setMenuUrl(`https://drive.google.com/viewerng/viewer?embedded=true&url=https://d2q28d4tfhhx03.cloudfront.net/73/menu/${file.name}`)
        await CartService.updateCart({
            ...cart,
            menu_url: `https://drive.google.com/viewerng/viewer?embedded=true&url=https://d2q28d4tfhhx03.cloudfront.net/73/menu/${file.name}`
        })
        handleClose()
    }

    const updateMenu = async () => {
        if (file == null) {
            await CartService.updateCart({
                ...cart,
                menu_url: menuUrl
            })
        } else {
            await sendFiles()
        }
        handleClose()
    }

    const deleteMenu = async () => {
        await CartService.updateCart({
            ...cart,
            menu_url: ''
        })
    }

    useEffect(() => {
        if (cart) {
            setFile(null)
            setMenuUrl(cart?.menu_url ?? '')
        }
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Stack direction={"column"} alignItems={"center"}>
                    <Typography variant={"h3"}>
                        {"Resturant's menu"}
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                {cart && cart.menu_url && cart.menu_url.length && <Stack direction={"row"} alignItems={"center"} justifyContent={"start"}>
                    <Typography fontWeight={500} sx={{flexShrink: 0}} textAlign={"center"}>Current Menu:</Typography>
                    <Button sx={{ml: 2, color: 'black'}} startIcon={<FileDownloadIcon/>} component={"a"} href={cart.menu_url} target='_blank'>
                        Download
                    </Button>
                    <Button sx={{color: 'black'}} startIcon={<DeleteForeverIcon/>} onClick={deleteMenu}>
                        Delete
                    </Button>
                </Stack>
                }
                <Typography fontWeight={500} sx={{mt: 2}} textAlign={"center"}>Insert menu URL:</Typography>
                <TextField
                    sx={{mt: 1}}
                    value={menuUrl ?? ''}
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Menu URL"
                    type="text"
                    onChange={(e) => setMenuUrl(e.target.value)}
                    fullWidth />
                <Typography fontWeight={500} sx={{my: 2}} textAlign={"center"}>Or upload new menu:</Typography>
                <FileUploader
                    multiple={false}
                    handleChange={handleChangeFile}
                    name="file"
                    types={["pdf", "PDF"]}
                    label={'Upload or drag and drop the file here'}
                    hoverTitle={"Drop here"}
                    children={
                        <Box
                            style={{borderStyle: "dashed", borderColor: "#EA4243", borderWidth: "2px", padding: "10px", justifyContent: "center", display: "flex", alignItems: "center"}}>
                            {file === null ?
                                <p className="text-center">
                                    No file uploaded,
                                    <br/>
                                    <u>Press</u> or drag and drop the file here
                                </p>
                                :
                                <p className="text-center">
                                    {`File uploaded: ${file.name}`}
                                    <br/>
                                    <u>Press</u> or drag and drop the file here to change it
                                </p>
                            }
                        </Box>
                    }
                />
            </DialogContent>
            <DialogActions sx={{flexDirection: 'row', justifyContent: 'center'}}>
                <Button sx={{width: 'auto', textTransform: 'none', fontWeight: 700}} onClick={updateMenu} variant={"contained"} color={"primary"} autoFocus>
                    Upload
                </Button>
                <Button sx={{width: 'auto', textTransform: 'none', fontWeight: 700}} onClick={handleClose} variant={"outlined"} color={"primary"}>Dismiss</Button>
            </DialogActions>
        </Dialog>
    );
}
