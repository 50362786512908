import axios from "axios";
import authHeader from "./user.service";

const getCarts = () => {
    return axios.get(process.env.REACT_APP_API + "carts", {headers: authHeader()})
}

const updateCart = (cart) => {
    return axios.put(process.env.REACT_APP_API + "carts", cart, {headers: authHeader()})
}

const uploadMenu = (path, type) => {
    return axios.post(process.env.REACT_APP_API + "apikey/file", {
        path: '73/menu/' + path,
        contentType: type
    }, {headers: authHeader()})
}

const CartService = {
    getCarts,
    updateCart,
    uploadMenu
};

export default CartService;
