import React, {useCallback, useEffect, useRef, useState} from "react";
import {Box, Button, IconButton, Paper, Slide, Stack, Typography} from "@mui/material";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import Map, {Layer, Marker, Source} from 'react-map-gl';
import staticCourseGeoJson from '../assets/course.json';
import golfclub_73 from '../assets/73_golfclub.json';
import cart_map from '../assets/cart_map.png';
import {ArrowBackIos, Close} from "@mui/icons-material";
import cart_picture from "../assets/cart_picture.png";
import UnlockAlert from "./UnlockAlert";
import {creekPathsProps, fillLayerProps, lineLayerProps, pinLayerProps, numberProps} from './map/styles';
import ClubService from "../services/club.service";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "./draw-control.scss";
import pin from '../assets/pin.png';

const MapboxMap = ({fullscreen, handleFullscreen, carts}) => {
    const map = useRef(null);
    const [drawFeatureID, setDrawFeatureID] = useState();
    const [drawFeature, setDrawFeature]= useState();
    const [courseGeoJson, setCourseGeoJson] = useState(null);
    const [drawControl, setDrawControl] = useState(new MapboxDraw({
        displayControlsDefault: false,
        userProperties: true,
        controls: {
            polygon: true,
            trash: true
        },
        styles: [
            {
                'id': 'gl-draw-polygon-fill-inactive',
                'type': 'fill',
                'filter': ['all', ['==', 'active', 'false'],
                    ['==', '$type', 'Polygon'],
                    ['!=', 'mode', 'static']
                ],
                'paint': {
                    'fill-color': '#ea4243',
                    'fill-outline-color': '#ea4243',
                    'fill-opacity': 0.1
                }
            },
            {
                'id': 'gl-draw-polygon-fill-active',
                'type': 'fill',
                'filter': ['all', ['==', 'active', 'true'],
                    ['==', '$type', 'Polygon']
                ],
                'paint': {
                    'fill-color': '#ea4243',
                    'fill-outline-color': '#ea4243',
                    'fill-opacity': 0.1
                }
            },
            {
                'id': 'gl-draw-polygon-midpoint',
                'type': 'circle',
                'filter': ['all', ['==', '$type', 'Point'],
                    ['==', 'meta', 'midpoint']
                ],
                'paint': {
                    'circle-radius': 3,
                    'circle-color': '#ea4243'
                }
            },
            {
                'id': 'gl-draw-polygon-stroke-inactive',
                'type': 'line',
                'filter': ['all', ['==', 'active', 'false'],
                    ['==', '$type', 'Polygon'],
                    ['!=', 'mode', 'static']
                ],
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-color': '#ea4243',
                    'line-width': 2
                }
            },
            {
                'id': 'gl-draw-polygon-stroke-active',
                'type': 'line',
                'filter': ['all', ['==', 'active', 'true'],
                    ['==', '$type', 'Polygon']
                ],
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-color': '#ea4243',
                    'line-dasharray': [0.2, 2],
                    'line-width': 2
                }
            },
            {
                'id': 'gl-draw-polygon-and-line-vertex-stroke-inactive',
                'type': 'circle',
                'filter': ['all', ['==', 'meta', 'vertex'],
                    ['==', '$type', 'Point'],
                    ['!=', 'mode', 'static']
                ],
                'paint': {
                    'circle-radius': 5,
                    'circle-color': '#fff'
                }
            },
            {
                'id': 'gl-draw-polygon-and-line-vertex-inactive',
                'type': 'circle',
                'filter': ['all', ['==', 'meta', 'vertex'],
                    ['==', '$type', 'Point'],
                    ['!=', 'mode', 'static']
                ],
                'paint': {
                    'circle-radius': 3,
                    'circle-color': '#ea4243'
                }
            },
            {
                'id': 'gl-draw-point-point-stroke-inactive',
                'type': 'circle',
                'filter': ['all', ['==', 'active', 'false'],
                    ['==', '$type', 'Point'],
                    ['==', 'meta', 'feature'],
                    ['!=', 'mode', 'static']
                ],
                'paint': {
                    'circle-radius': 5,
                    'circle-opacity': 1,
                    'circle-color': '#fff'
                }
            },
            {
                'id': 'gl-draw-point-inactive',
                'type': 'circle',
                'filter': ['all', ['==', 'active', 'false'],
                    ['==', '$type', 'Point'],
                    ['==', 'meta', 'feature'],
                    ['!=', 'mode', 'static']
                ],
                'paint': {
                    'circle-radius': 3,
                    'circle-color': '#ea4243'
                }
            },
            {
                'id': 'gl-draw-point-stroke-active',
                'type': 'circle',
                'filter': ['all', ['==', '$type', 'Point'],
                    ['==', 'active', 'true'],
                    ['!=', 'meta', 'midpoint']
                ],
                'paint': {
                    'circle-radius': 7,
                    'circle-color': '#fff'
                }
            },
            {
                'id': 'gl-draw-point-active',
                'type': 'circle',
                'filter': ['all', ['==', '$type', 'Point'],
                    ['!=', 'meta', 'midpoint'],
                    ['==', 'active', 'true']
                ],
                'paint': {
                    'circle-radius': 5,
                    'circle-color': '#ea4243'
                }
            },
            {
                'id': 'gl-draw-polygon-fill-static',
                'type': 'fill',
                'filter': ['all', ['==', 'mode', 'static'],
                    ['==', '$type', 'Polygon']
                ],
                'paint': {
                    'fill-color': '#404040',
                    'fill-outline-color': '#404040',
                    'fill-opacity': 0.1
                }
            },
            {
                'id': 'gl-draw-polygon-stroke-static',
                'type': 'line',
                'filter': ['all', ['==', 'mode', 'static'],
                    ['==', '$type', 'Polygon']
                ],
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-color': '#404040',
                    'line-width': 2
                }
            },

            // end default themes provided by MB Draw
            // end default themes provided by MB Draw
            // end default themes provided by MB Draw
            // end default themes provided by MB Draw
        ]
    }));

    const [lng, setLng] = useState(10.903952511539307);
    const [lat, setLat] = useState(44.54112997608319);
    const golfclubId = process.env.REACT_APP_GOLFCLUB_ID;

    // const [lng, setLng] = useState(7.5570);
    // const [lat, setLat] = useState(45.1909);
    const [zoom, setZoom] = useState(14.4);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedCart, setSelectedCart] = useState();
    const [lockAlertOpen, setLockAlertOpen] = useState(false);
    const [cartLock, setCartLock] = useState(false);
    const [dangerZones, setDangerZones] = useState([])
    const [pinPositions, setPinPositions] = useState(null)

    const handleLockAlertClose = () => {
        setLockAlertOpen(false);
    }

    const handleCloseMap = (fullscreen, state) => {
        handleFullscreen(fullscreen);
        setShowPopup(state);
    }

    const cartData = [[
        {key: 'model', label: 'Model', value: 'Alba GC'},
        {key: 'number', label: 'N. Cart', value: '1'},
        {key: 'license', label: 'Registration', value: 'ATOTO_DEMO', mb: 5},
        {key: 'speed', label: 'Speed', value: '-'},
        {key: 'temperature', label: 'Temperature', value: '-'},
    ]];

    const onUpdateDangerZone = useCallback(e => {
        const _features = drawControl.getAll();
        ClubService.setDangerZones(golfclubId, _features).then();
    }, []);

    const onCreateDangerZone = useCallback(e => {
        const _features = drawControl.getAll();
        ClubService.setDangerZones(golfclubId, _features).then();
    }, []);

    const onDeleteDangerZone = useCallback(e => {
        const _features = drawControl.getAll();
        ClubService.setDangerZones(golfclubId, _features).then();
    }, []);

    useEffect(() => {
        if (map.current) {
            map.current.resize();
            if (fullscreen) {
                if (!map.current.hasControl(drawControl)) {
                    map.current.addControl(drawControl);
                }
                fetchData().then()
            } else {
                map.current.removeControl(drawControl);
            }
        }
    }, [fullscreen])

    useEffect(() => {
        if (map && map.current) {
            map.current.on('draw.create', onCreateDangerZone);
            map.current.on('draw.update', onCreateDangerZone);
            map.current.on('draw.delete', onDeleteDangerZone);
            map.current.loadImage(pin, (error, image) => {
                if (error) throw error;
                map.current.addImage('pin', image);
            });
        }
        return () => {
            if (map && map.current) {
                map.current.off('draw.create', onCreateDangerZone);
                map.current.off('draw.update', onCreateDangerZone);
                map.current.off('draw.delete', onDeleteDangerZone);
            }
        }
    }, [map.current])

    useEffect(() => {
        setCourseGeoJson(golfclub_73);
        fetchPinPositions().then()
    }, [])

    const fetchPinPositions = async () => {
        const pinPositions_1 = await ClubService.getPinPositions(golfclubId, 789);
        const pinPositions_2 = await ClubService.getPinPositions(golfclubId, 683);
        const pinPositions = [...pinPositions_1.data.res, ...pinPositions_2.data.res]
        const pinPositionsGeoJson = {
            "type": "FeatureCollection",
            "features": pinPositions.map((p) => ({
                "type": "Feature",
                "properties": {
                    "id": p.id,
                },
                "geometry": p.geom,
            }))
        }
        setPinPositions(pinPositionsGeoJson)
    }

    const fetchData = async () => {
        const dangerZones = await ClubService.getDangerZones(golfclubId);
        setDangerZones(dangerZones.data.dangerZones)
        if (drawControl) {
            dangerZones.data.dangerZones.forEach((d) =>
                drawControl.add(d.geom)
            )
            console.log(drawControl.getAll())
        }
    }

    const cartClick = (cart) => {
        setSelectedCart(cart);
        setShowPopup(true);
    }

    return (
        <div id="map" className={fullscreen ? "map-container fullscreen" : "map-container"}>
            {golfclubId && <Map
                ref={map}
                initialViewState={{
                    longitude: lng,
                    latitude: lat,
                    zoom: zoom
                }}
                onZoom={(e) => setZoom(e.target.getZoom())}
                style={{width: '100%', height: '100%'}}
                mapStyle="mapbox://styles/pin-vision/cl9jw0lu700jg14par798x8qt"
                minZoom={8}
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            >
                {courseGeoJson && <Source type={"geojson"} id={"course"} data={courseGeoJson}>
                    <Layer {...fillLayerProps} />
                    <Layer {...lineLayerProps} />
                    <Layer {...creekPathsProps} />
                    <Layer {...numberProps} />
                </Source>}
                {pinPositions && <Source type={"geojson"} id={"pins"} data={pinPositions}>
                    <Layer {...pinLayerProps} />
                </Source>}
                {carts.map(cart => (
                    <Marker key={cart.cartId}
                            longitude={cart.lastLocation[0]}
                            latitude={cart.lastLocation[1]}
                            style={{cursor: 'pointer'}}
                            onClick={() => cartClick(cart)}>
                        <Box sx={{backgroundColor: '#fff', borderRadius: '8px', padding: '6px 8px'}}>
                            <Typography fontWeight={700} textAlign={"center"} fontSize={14}
                                        variant={"body1"}>Cart {cart.number}</Typography>
                        </Box>
                        <img alt={""} src={cart_map} style={{width: 6 * zoom}}/>
                    </Marker>))}
                {fullscreen && <Stack sx={{position: 'absolute', left: 24, top: 48, zIndex: 9}}
                                      alignItems={"center"} justifyContent={"center"}>
                    <Button onClick={() => handleCloseMap(!fullscreen, false)}
                            sx={{
                                px: 2,
                                py: 1,
                                backgroundColor: 'rgba(255,255,255,0.5)',
                                color: 'black',
                                borderRadius: '8px'
                            }} startIcon={<ArrowBackIos/>}>
                        <Typography variant={"body1"} textTransform={"none"}>Back</Typography>
                    </Button>
                </Stack>}
            </Map>}
            {!fullscreen && <Stack sx={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, zIndex: 99}}
                                   alignItems={"center"} justifyContent={"center"}
                                   onClick={() => handleCloseMap(!fullscreen, false)}>
                <Typography className={"open-map-cta"} variant={"h2"} color={"white"}
                            sx={{textShadow: '0px 2px 23px 0px rgba(0, 0, 0, 0.25)'}}>
                    Open Map
                </Typography>
            </Stack>}
            <Slide direction="right" in={showPopup} mountOnEnter unmountOnExit>
                <Paper variant={"card"} sx={{
                    position: 'absolute',
                    left: 24,
                    top: 102,
                    p: 2,
                    width: 300,
                    maxHeight: '80vh',
                    overflow: 'auto'
                }}>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"}>
                        <IconButton onClick={() => setShowPopup(false)}>
                            <Close/>
                        </IconButton>
                    </Stack>
                    <img src={cart_picture} style={{width: '100%'}}/>
                    <Typography textAlign={"center"} variant={"h2"}>Cart {selectedCart?.id}</Typography>
                    {cartData[(selectedCart?.id) > 0 ? (selectedCart?.id - 1) : 0].map((property) =>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}
                               sx={{mb: property.mb ?? 1}}>
                            <Typography fontWeight={700}>{property.label}:</Typography>
                            <Typography>{property.value}</Typography>
                        </Stack>
                    )}
                    <Button color={"primary"} variant={"contained"} sx={{mb: 2, mt: 5}}
                            onClick={() => setLockAlertOpen(true)}>
                        {selectedCart?.locked ? 'Unlock Cart' : 'Lock Cart'}
                    </Button>
                    <Button color={"secondary"} variant={"contained"}>Open Chat</Button>
                </Paper>
            </Slide>
            <UnlockAlert cart={selectedCart} open={lockAlertOpen} handleClose={handleLockAlertClose}/>
        </div>

    )
}

export default MapboxMap;
