import React, {useEffect} from "react";
import {useNavigate, useParams} from 'react-router-dom'
import {Box, CircularProgress} from "@mui/material";
import jwt from 'jwt-decode'

const SSO = () => {
    let navigate = useNavigate()
    const {token} = useParams();

    useEffect(() => {
        fetchData().then()
        setTimeout(function () {
            navigate('/', {replace: true})
            window.location.reload();
        }, 700)
    }, [])

    async function fetchData() {
        const user = jwt(token);
        localStorage.setItem("sub", user.sub);
        localStorage.setItem("user", JSON.stringify({token: token}));
    }

    return(
        <main>
            <Box style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <CircularProgress color="secondary"/>
            </Box>
        </main>
    )
}

export default SSO
